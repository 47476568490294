/* You can add global styles to this file, and also import other style files */
a {
  text-decoration: none;
  color: var(--hf-link-text);
}

a:hover {
  // text-decoration: underline;
  color: var(--hf-link-text);
}
